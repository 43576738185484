import { logDOM } from '@testing-library/react';
import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../../Icons/Loading.js'

export default function User(props) {

    const {
        data,
        user,
        allUsers,
        currentDataRoom,
        setFilesSelectedUserCanAccess,
        setSocketCommand,
        setSearchResults,
        setCurrentSearch,
        setNumOfMatches,
        componentState,
        setComponentState,
        currentAccessActivitySearch,
        setCurrentAccessActivitySearch,
        setAccessResults,
        setActivityResults,
        themes,
        currentTheme
    } = props;

    const [accessOpen, setAccessOpen] = useState(false);
    const [activityOpen, setActivityOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const getUserAccess = (user) => {
        let dr = currentDataRoom ? [currentDataRoom] : Object.keys(data)
        setSocketCommand({ type: 'requestUserAccesses', data: { user: user, dr: dr } });
        setLoading(true);
    };

    const getUserActivity = (user) => {
        let dr = currentDataRoom ? [currentDataRoom] : Object.keys(data)
        setSocketCommand({ type: 'requestUserActivity', data: { user: user, dr: dr } });
        setLoading(true);
    };

    const handleUserOptionClick = (type) => {
        if (type === 'access') {
            if (activityOpen) setActivityOpen(false);
            setAccessOpen(!accessOpen);
        } else if (type === 'activity') {
            if (accessOpen) setAccessOpen(false);
            setActivityOpen(!activityOpen);
        };
    };

    const handleAccessLevelOptionClick = (level, values) => {
        setCurrentAccessActivitySearch(level);
        setAccessResults(values);
        setActivityResults([]);
        setSearchResults([]);
        setCurrentSearch(null);
        setNumOfMatches(null);

        let componentStateClone = { ...componentState };
        componentStateClone.users.visibleUser = level ? user : null;
        setComponentState(componentStateClone);
    };

    const handleActivityLevelOptionClick = (level, values) => {
        setCurrentAccessActivitySearch(level);
        setActivityResults(values);
        setAccessResults([]);
        setSearchResults([]);
        setCurrentSearch(null);
        setNumOfMatches(null);

        let componentStateClone = { ...componentState };
        componentStateClone.users.visibleUser = level ? user : null;
        setComponentState(componentStateClone);
    };

    useEffect(() => {
        if (accessOpen) {
            getUserAccess(user);
        };
    }, [accessOpen]);

    useEffect(() => {
        if (activityOpen) {
            getUserActivity(user);
        };
    }, [activityOpen]);

    useEffect(() => {
        if (allUsers[user].access) setLoading(false);
    }, [allUsers[user].access]);

    useEffect(() => {
        if (allUsers[user].activity) setLoading(false);
    }, [allUsers[user].activity]);

    return (
        <div className="userInfo">
            <p>{user}</p>
            <div className="userInfoOptions">
                <p onClick={() => handleUserOptionClick('access')}>access</p>
                <p onClick={() => handleUserOptionClick('activity')}>activity</p>
            </div>
            {loading &&
                <Loading
                    color={themes[currentTheme].altText}
                    size={'24px'}
                />
            }
            {accessOpen && allUsers[user].access &&
                <div className="accessLevelsContainer">
                    {Object.entries(allUsers[user].access).map(([key, val]) => {
                        return <div className="accessOption">
                            <p onClick={() => handleAccessLevelOptionClick(key, val)}>{key}</p>
                            {currentAccessActivitySearch === key && componentState.users.visibleUser === user &&
                                <p onClick={() => handleAccessLevelOptionClick(null, [])}>x</p>
                            }
                        </div>
                    })}
                </div>
            }
            {activityOpen && allUsers[user].activity &&
                <div className="accessLevelsContainer">
                    {Object.keys(allUsers[user].activity).length > 0 && Object.entries(allUsers[user].activity).map(([key, val]) => {
                        return <div className="accessOption">
                            <p onClick={() => handleActivityLevelOptionClick(key, val)}>{key}</p>
                            {currentAccessActivitySearch === key && componentState.users.visibleUser === user &&
                                <p onClick={() => handleActivityLevelOptionClick(null, [])}>x</p>
                            }
                        </div>
                    })}
                    {Object.keys(allUsers[user].activity).length === 0 &&
                        <p>no recent activity</p>
                    }
                </div>
            }
        </div>
    )

};