import React, { useState } from 'react';
import { InspectorAddRemove } from '..//Utils/Utils.js'
import './SearchIcon.css';

export default function SearchIcon(props) {

    const {
        inMenu,
        clickable,
        panelOrder,
        setPanelOrder,
        showSearch,
        setShowSearch
    } = props;

    const [showLabel, setShowLabel] = useState(false);

    return (
        <div
            className={inMenu ? "searchIconContainerMenu" : "searchIconContainerStatusBar"}
            style={clickable ? {pointerEvents: 'auto', cursor: 'pointer'} : {pointerEvents: 'none', cursor: 'auto'}}
            onClick={() => setPanelOrder(InspectorAddRemove([...panelOrder], 'search'))}
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
        >
            <div className={inMenu ? "searchIcon" : "searchIcon searchIconAlt"}>
                <div></div>
                <div></div>
            </div>
            {showLabel && inMenu &&
                <p className="label">search</p>
            }
        </div>
    )
}