import React, { useState } from 'react';
import { InspectorAddRemove } from '..//Utils/Utils.js'
import './SettingsIcon.css';

export default function SettingsIcon(props) {

    const {
        inMenu,
        clickable,
        panelOrder,
        setPanelOrder,
        showSettings,
        setShowSettings
    } = props;

    const [showLabel, setShowLabel] = useState(false);

    return (
        <div
            className={inMenu ? "settingsIconContainerMenu" : "settingsIconContainerStatusBar"}
            style={clickable ? {pointerEvents: 'auto', cursor: 'pointer'} : {pointerEvents: 'none', cursor: 'auto'}}
            onClick={() => setPanelOrder(InspectorAddRemove([...panelOrder], 'settings'))}
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
        >
            <div className={inMenu ? "settingsIcon" : "settingsIcon settingsIconAlt"}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            {showLabel && inMenu &&
                <p className="label">settings</p>
            }
        </div>
    )
}
