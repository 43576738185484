import React, { useState } from 'react';
import { InspectorAddRemove } from '..//Utils/Utils.js';
import './FolderContentsIcon.css';

export default function FolderContentsIcon(props) {

    const {
        inMenu,
        clickable,
        panelOrder,
        setPanelOrder
    } = props;

    const [showLabel, setShowLabel] = useState(false);

    return (
        <div
            className={inMenu ? "folderContentsIconContainerMenu" : "folderContentsIconContainerStatusBar"}
            style={clickable ? {pointerEvents: 'auto', cursor: 'pointer'} : {pointerEvents: 'none', cursor: 'auto'}}
            onClick={() => setPanelOrder(InspectorAddRemove([...panelOrder], 'contents'))}
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
        >
            <div className={inMenu ? "folderContentsIcon" : "folderContentsIcon folderContentsIconAlt"}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            {showLabel && inMenu &&
                <p className="label">files</p>
            }
        </div>
    )
}
