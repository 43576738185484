import React, { useEffect, useState, useRef } from 'react';
import FolderContentsIcon from '../../../Icons/FolderContentsIcon.js';
import MaximizeIcon from '../../../Icons/MaximizeIcon.js';
import MoveComponentIcon from '../../../Icons/MoveComponentIcon.js';
import './FolderContents.css';

export default function FolderContents(props) {

    const {
        data,
        treeData,
        filesToInspect,
        highlightedPath,
        panelOrder,
        setPanelOrder,
        showFolderContents,
        setShowFolderContents,
        viewLevel,
        searchResults,
        componentState,
        setComponentState,
        inspectorWidth,
        rearrange,
        themes,
        currentTheme
    } = props;

    const [hover, setHover] = useState(false);
    const [componentPosition, setComponentPosition] = useState(null);

    const componentRef = useRef();
    const filesRef = useRef();

    const handleColumnChange = (e) => {
        e.stopPropagation();
        let componentStateClone = { ...componentState };
        componentStateClone.contents.columns = componentStateClone.contents.columns + 1;
        if (componentStateClone.contents.columns > 3) componentStateClone.contents.columns = 1;
        setComponentState(componentStateClone);
    };

    const formatFileName = (name, fileType) => {
        name = name.replace(fileType, '');
        if (name.length >= 30) name = name.substring(0, 27) + '...';
        return name;
    };

    return (
        <div
            ref={componentRef}
            id="folderContents"
            style={{
                position: componentState.contents.beingDragged ? 'absolute' : 'relative',
                top: componentState.contents.beingDragged ? `${componentPosition.y}px` : '0px',
                left: componentState.contents.beingDragged ? `${componentPosition.x}px` : '0px',
                marginTop: componentState.contents.beingDragged && '0px',
                width: rearrange ? `calc(${inspectorWidth}vw - 24px)` : `calc(100% - 24px)`,
                height: componentState.contents.maximize ? '500px' : '50px'
            }}
            onClick={(e) => { e.stopPropagation() }}
        >

            <FolderContentsIcon
                inMenu={false}
                clickable={true}
                panelOrder={panelOrder}
                setPanelOrder={setPanelOrder}
                showFolderContents={showFolderContents}
                setShowFolderContents={setShowFolderContents}
            />

            <div id="folderContentsStatusBar">
                <MaximizeIcon
                    component={'contents'}
                    componentState={componentState}
                    setComponentState={setComponentState}
                />
                <MoveComponentIcon
                    component={'contents'}
                    componentRef={componentRef}
                    componentState={componentState}
                    setComponentState={setComponentState}
                    setComponentPosition={setComponentPosition}
                />
                <div id="columnsContainer" onClick={(e) => handleColumnChange(e)}>
                    {[...Array(componentState.contents.columns)].map(elem => {
                        return <div className="column"></div>
                    })}
                </div>
                <div className="folderContentsItemContainer">
                    <p
                        onMouseOver={() => setHover('depth')}
                        onMouseOut={() => setHover(false)}
                    >
                        {viewLevel}
                    </p>
                    {hover === 'depth' &&
                        <p className="label">depth</p>
                    }
                </div>
                <div className="folderContentsItemContainer">
                    <p
                        onMouseOver={() => setHover('children')}
                        onMouseOut={() => setHover(false)}
                    >
                        {filesToInspect.length}
                    </p>
                    {hover === 'children' &&
                        <p className="label">files</p>
                    }
                </div>
            </div>

            <div
                id="folderContentsInner"
                style={{ display: componentState.contents.maximize ? 'flex' : 'none' }}
            >
                <div
                    id="files"
                    style={{ gridTemplateColumns: `repeat(${componentState.contents.columns}, 1fr)` }}
                    ref={filesRef}
                >
                    {filesToInspect.map(elem => {

                        if (!treeData || !treeData[elem]) return;

                        let item = treeData[elem];

                        return <div
                            className={searchResults.includes(elem) ? "fileSearchResult" : "file"}
                            style={{
                                height: `${(componentState.contents.columns * 30) + 5}px`,
                            }}
                        >
                            <div>
                                <div className="fileIcon"></div>
                                <p className='fileType'>{item.fileType.replace('.', '')}</p>
                            </div>
                            {componentState.contents.columns &&
                                <a className="fileLink" href={item.link} target="_blank" rel="noreferrer">
                                    {formatFileName(item.name, item.fileType)}
                                </a>
                            }
                        </div>

                    })}
                </div>
                <div id="path">
                    {highlightedPath && highlightedPath.slice(0).reverse().map((elem, i, arr) => {

                        if (!treeData || !treeData[elem]) return;

                        let item = treeData[elem];

                        return (
                            <div className="pathElement">
                                <div className="pathElementInner">
                                    <a href={item.link} target="_blank" rel="noreferrer">{item.name}</a>
                                    <p>{i === arr.length - 1 ? '' : '>'}</p>
                                </div>
                            </div>
                        )

                    })}
                </div>
            </div>

        </div>
    )
}
