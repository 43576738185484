import React from 'react'
import './AvatarIcon.css';

export default function AvatarIcon(props) {

    const {
        userOnline
    } = props;

    return (
        <div className={userOnline ? 'avatarIconContainer online' : 'avatarIconContainer'}>
            <div className="avatarIcon">
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
