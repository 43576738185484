import React, { useEffect, useRef, useState } from 'react';
import './UpdaterIcon.css';

export default function UpdaterIcon(props) {

    const {
        updaterStatus,
        dataRoom
    } = props;

    const [showLabel, setShowLabel] = useState(false);

    return (
        <div
            className={
                updaterStatus[dataRoom] === 'Active' ? "updaterIconContainer updaterActive" :
                    updaterStatus[dataRoom] === 'DOWN' ? "updaterIconContainer updaterDown" :
                        "updaterIconContainer updaterNone"
            }
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
        >
            <div></div>
            <div></div>
            {showLabel &&
                <p>{
                    updaterStatus[dataRoom] === 'Active' ? "updater active" :
                        updaterStatus[dataRoom] === 'DOWN' ? "updater down" :
                            "no updater"
                }</p>
            }
        </div>
    )

}
