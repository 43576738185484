import React, { useState } from 'react';
import { InspectorAddRemove } from '..//Utils/Utils.js'
import './UsersIcon.css';

export default function UsersIcon(props) {

    const {
        inMenu,
        clickable,
        panelOrder,
        setPanelOrder,
        showUsers,
        setShowUsers
    } = props;

    const [showLabel, setShowLabel] = useState(false);

    return (
        <div
            className={inMenu ? "usersIconContainerMenu" : "usersIconContainerStatusBar"}
            style={clickable ? { pointerEvents: 'auto', cursor: 'pointer' } : { pointerEvents: 'none', cursor: 'auto' }}
            onClick={() => setPanelOrder(InspectorAddRemove([...panelOrder], 'users'))}
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
        >
            <div className={inMenu ? "usersIcon" : "usersIcon usersIconAlt"}>
                <div></div>
                <div></div>
            </div>
            {showLabel && inMenu &&
                <p className="label">users</p>
            }
        </div>
    )
}
