import React, { useState, useEffect, useRef } from 'react'
import './MoveComponentIcon.css';

export default function MoveComponentIcon(props) {

    const {
        component,
        componentRef,
        componentState,
        setComponentState,
        setComponentPosition
    } = props;

    let xDiff = useRef(null);
    let yDiff = useRef(null);

    const calculateComponentPosition = (e) => {
        console.log(e.clientX);
        let rect = componentRef.current.getBoundingClientRect();

        xDiff.current = e.clientX - rect.left;
        yDiff.current = e.clientY - rect.top;
        console.log(xDiff);

        let x = e.clientX - xDiff.current;
        let y = e.clientY - yDiff.current;
        console.log(x, y);
        return { x: x, y: y }
    }

    const handleMouseDown = (e) => {

        setComponentPosition(calculateComponentPosition(e));
        let componentStateClone = { ...componentState };
        componentStateClone[component].beingDragged = true;
        setComponentState(componentStateClone);

    };

    const handleMouseUp = () => {
        let componentStateClone = { ...componentState };
        componentStateClone[component].beingDragged = false;
        setComponentState(componentStateClone);
    };

    const handleMouseMove = (e) => {
        e.stopPropagation();

        if (componentState[component].beingDragged) {
            let x = e.clientX - xDiff.current;
            let y = e.clientY - yDiff.current;
            console.log('>>>', x, y);
            setComponentPosition({x: x, y: y});
        };

    };

    return (
        <div
            className="moveComponentIconContainer"
            onMouseDown={(e) => handleMouseDown(e)}
            onMouseUp={() => handleMouseUp()}
            onMouseMove={(e) => { handleMouseMove(e) }}
            style={{ cursor: componentState[component].beingDragged ? 'grabbing' : 'grab' }}
        >
            <div className="moveComponentIcon">
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
                <div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}