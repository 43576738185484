import React, { useEffect, useRef } from 'react'
import './Background.css';

export default function Background(props) {

    const {
        backgroundPosition,
        // colourToUpdate,
        // settings
        currentTheme,
        themes
    } = props;

    const backgroundRef = useRef();

    useEffect(() => {
        backgroundRef.current.style.transform = `rotate(${backgroundPosition}deg)`;
    }, [backgroundPosition]);

    // useEffect(() => {
    //     if (colourToUpdate && colourToUpdate.type === 'background') {
    //         backgroundRef.current.style.background = `radial-gradient(ellipse at 50% 0%, rgb(211, 210, 210), rgba(230, 230, 230, 0.3), transparent), radial-gradient(ellipse at 50% 100%, ${settings.appearance.colours.background.selected}, rgba(0, 0, 139, 0.4))`;
    //     };
    // }, [colourToUpdate]);

    return (
        <div
            id="background"
            ref={backgroundRef}
            style={{ backgroundColor: themes[currentTheme].two }}
        ></div>
    )
}
