import React, { useEffect, useRef, useState } from 'react';
import './Loading.css';

export default function Loading(props) {

    const {
        color,
        size
    } = props;

    const [loadingCount, setLoadingCount] = useState(0);
    const [loadingAnim] = useState([`.`, `..`, `...`]);

    let loadingCountRef = useRef(loadingCount);
    let loadingIntRef = useRef();

    useEffect(() => {

        loadingIntRef.current = setInterval(() => {
            console.log('loading');
            loadingCountRef.current = loadingCountRef.current < loadingAnim.length - 1 ? loadingCountRef.current + 1 : 0;
            setLoadingCount(loadingCountRef.current);
        }, 200);

        return () => {
            console.log('stop loading');
            setLoadingCount(0);
            clearInterval(loadingIntRef.current);
        };

    }, []);

    return (
        <p
            id="loadingIndicator"
            style={{
                color: color,
                fontSize: size,
                width: size
            }}
        >
            {loadingAnim[loadingCount]}
        </p>
    )
}
