import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Branch } from './Branch';
// import { Files } from './Files';
import { File } from './File';
import { Foliage } from './Foliage';
import { Base } from './Base';
import { calculatePath } from '../../Utils/Utils.js';

export function Tree(props) {

    const {
        data,
        treeData,
        name,
        index,
        currentDataRoom,
        setCurrentDataRoom,
        viewLevel,
        setViewLevel,
        highestDepth,
        revealThreshold,
        currentZone,
        clickedElement,
        setClickedElement,
        highlightedPath,
        cameraPosition,
        setCameraPosition,
        cameraRef,
        setLoading,
        showBranchLabels,
        searchResults,
        treePositions,
        setTreePositions,
        currentTheme,
        themes,
        roots,
        setRoots,
        onlineUsers,
        showFiles,
        pathToResultItems,
        updateTree,
        dataOrganisedByZone,
        dataOrganisedByDepth,
        filesSelectedUserCanAccess,
        currentAccessActivitySearch,
        accessResults,
        activityResults,
        updatedItems,
        setUpdatedItems,
        setJumpTo
    } = props;

    const treeRef = useRef();

    // Logic functions ============================================================================

    const checkIfBranchVisible = (key, value) => {

        if (
            // If elements ARE NOT the current data room only show them if they are depth 0 and 1
            (!isCurrentDataRoom(name) && (isDepthZeroOrOne(value.depth) || isInSearchResultOrPathToSearchResult(key)))
            ||
            // If elements ARE in the current data room
            (
                isCurrentDataRoom(name) &&
                (
                    // Always show elements at depth 0 and 1
                    isDepthZeroOrOne(value.depth) ||
                    (
                        // If the depth of the element is less than the view level + the reveal threshold
                        // And there isn't a current zone or the current zone is the root
                        isBelowViewThreshold(value.depth) &&
                        (
                            (
                                isInCurrentZone(value.zone) &&
                                (isDescendantOfClickedElement(clickedElement).includes(key) ||
                                    isInHighlightedPath(key))
                            ) ||
                            currentZoneIsRootOrNull()
                        )
                    ) ||
                    (
                        isInSearchResultOrPathToSearchResult(key) ||
                        isInActivityResultOrPathToActivityResult(key) ||
                        isInAccessResult(key)
                    )
                )
            )
        ) {
            return true
        } else return false

    }

    const checkIfFileVisible = (key, value) => {

        if (
            isCurrentDataRoom(name) &&
            (
                isDepthZeroOrOne(value.depth) ||
                isBelowViewThreshold(value.depth) &&
                (
                    (
                        isInCurrentZone(value.zone) &&
                        (isDescendantOfClickedElement(clickedElement).includes(key) ||
                            isInHighlightedPath(key))
                    ) ||
                    currentZoneIsRootOrNull()
                ) ||
                (
                    isInSearchResultOrPathToSearchResult(key) ||
                    isInActivityResultOrPathToActivityResult(key) ||
                    isInAccessResult(key)
                )
            )
        ) {
            return true
        } else return false

    }

    const isCurrentDataRoom = (name) => {
        if (name === currentDataRoom) return true
        else return false
    }

    const isDepthZeroOrOne = (depth) => {
        if (depth === 0 || depth === 1) return true
        else return false
    }

    const isBelowViewThreshold = (depth) => {
        if (depth <= viewLevel + revealThreshold[name]) return true
        else return false
    }

    const isInHighlightedPath = (id) => {
        if (highlightedPath.includes(id)) return true
        else return false
    }

    const isDescendantOfClickedElement = (target) => {

        if (!clickedElement) return [];

        let arr = [];
        for (const [key, value] of Object.entries(treeData)) {
            if (value.zone === currentZone) {
                let path = calculatePath(key, treeData, target)
                if (path.includes(target)) arr = arr.concat(path);
            };
        };

        return arr

    }

    const isInCurrentZone = (zone) => {
        if (currentZone === zone) return true
        else return false
    }

    const currentZoneIsRootOrNull = () => {
        if (currentZone === 'root' || currentZone === null) return true
        else return false
    }

    const isInSearchResultOrPathToSearchResult = (id) => {
        if (searchResults.includes(id) || pathToResultItems.includes(id)) return true
        else return false
    }

    const isInActivityResultOrPathToActivityResult = (id) => {
        if (activityResults.includes(id) || pathToResultItems.includes(id)) return true
        else return false
    }

    const isInAccessResult = (id) => {
        if (treeData[id].type === 'folder') {
            if (accessResults.includes(id)) return true
            else return false
        } else {
            if (accessResults.includes(id) && !accessResults.includes(treeData[id].parent)) return true
            else return false
        }
    }

    // useEffect(() => {
    //     if (updatedItems.length) {
    //         console.log('update tree')
    //         setTimeout(() => {
    //             setUpdatedItems([])
    //             console.log('re-render')
    //         }, 1)
    //     };
    // }, [updatedItems]);

    return (
        <group
            key={name}
            name={name}
            ref={treeRef}
            position={[
                index * 20,
                0,
                0
            ]}
        >
            {!updateTree && Object.entries(treeData).map(([key, value], i, arr) => {

                if (value.type === 'folder' && !updatedItems.includes(value.ID)) {

                    if (checkIfBranchVisible(key, value) || updatedItems.includes(key)) {

                        return (
                            <Branch
                                data={data}
                                treeData={treeData}
                                ID={key}
                                info={value}
                                name={name}
                                index={index}
                                currentDataRoom={currentDataRoom}
                                viewLevel={viewLevel}
                                highestDepth={highestDepth}
                                setCurrentDataRoom={setCurrentDataRoom}
                                clickedElement={clickedElement}
                                setClickedElement={setClickedElement}
                                highlightedPath={highlightedPath}
                                cameraPosition={cameraPosition}
                                setCameraPosition={setCameraPosition}
                                cameraRef={cameraRef}
                                currentZone={currentZone}
                                showBranchLabels={showBranchLabels}
                                searchResults={searchResults}
                                accessResults={accessResults}
                                activityResults={activityResults}
                                currentTheme={currentTheme}
                                themes={themes}
                                roots={roots}
                                setRoots={setRoots}
                                showFiles={showFiles}
                                updateTree={updateTree}
                                updatedItems={updatedItems}
                                setUpdatedItems={setUpdatedItems}
                                setJumpTo={setJumpTo}
                            />
                        )

                    };

                } else if (value.type === 'file') {

                    if (showFiles) {

                        if (checkIfFileVisible(key, value)) {

                            return (
                                <File
                                    treeData={treeData}
                                    ID={key}
                                    info={value}
                                    name={name}
                                    currentDataRoom={currentDataRoom}
                                    viewLevel={viewLevel}
                                    highestDepth={highestDepth}
                                    searchResults={searchResults}
                                    accessResults={accessResults}
                                    activityResults={activityResults}
                                    fileType={value.type}
                                    roots={roots}
                                    clickedElement={clickedElement}
                                    setClickedElement={setClickedElement}
                                    setCameraPosition={setCameraPosition}
                                    cameraRef={cameraRef}
                                    themes={themes}
                                    currentTheme={currentTheme}
                                    updatedItems={updatedItems}
                                    setUpdatedItems={setUpdatedItems}
                                />
                            )

                        }


                    } else return null

                };

            })}

            <Base
                name={name}
                currentDataRoom={currentDataRoom}
                setCurrentDataRoom={setCurrentDataRoom}
                setClickedElement={setClickedElement}
                setViewLevel={setViewLevel}
                onlineUsers={onlineUsers}
                themes={themes}
                currentTheme={currentTheme}
                viewLevel={viewLevel}
            />

        </group>
    )

};