import React, { useEffect, useRef, useState } from 'react';
import UsersIcon from '../Icons/UsersIcon';
import SearchIcon from '../Icons/SearchIcon';
import SettingsIcon from '../Icons/SettingsIcon';
import MapIcon from '../Icons/MapIcon';
import FolderContentsIcon from '../Icons/FolderContentsIcon';
import Loading from '../Icons/Loading';
import UpdaterIcon from '../Icons/UpdaterIcon';
import AddDRIcon from '../Icons/AddDRIcon';
import SearchBar from './Inspector/Search/SearchBar';
import { calculateCameraPosition } from '../Utils/Utils';
import axios from "axios";
import { gsap } from "gsap";
import './Menu.css';

export default function Menu(props) {

    const {
        data,
        setData,
        treeData,
        currentDataRoom,
        setCurrentDataRoom,
        account,
        setSearchableValues,
        dashboardVisible,
        loading,
        setLoading,
        settings,
        setSettings,
        viewLevel,
        setViewLevel,
        highestDepth,
        setHighestDepth,
        panelOrder,
        setPanelOrder,
        inspectorWidth,
        setInspectorWidth,
        selectedDataRoom,
        setSelectedDataRoom,
        setClickedElement,
        availableDataRooms,
        availableDataRoomGroups,
        setSocketCommand,
        roots,
        setRoots,
        valuesOfType,
        setValuesOfType,
        currentSearchTextInput,
        setCurrentSearchTextInput,
        dropDownLimit,
        setDropDownLimit,
        currentSearch,
        setCurrentSearch,
        setSearchResults,
        numOfMatches,
        setNumOfMatches,
        searchType,
        searchCategory,
        onlineUsers,
        setPathToResultItems,
        updaterStatus,
        themes,
        currentTheme,
        setCurrentAccessActivitySearch,
        setAccessResults,
        setActivityResults
    } = props;

    const [showMenu, setShowMenu] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [mousePosition, setMousePosition] = useState(null);
    const [showAvailableDataRooms, setShowAvailableDataRooms] = useState(true);
    const [dataRoomOptions, setDataRoomOptions] = useState(null);
    const [showUsersInDataRoomList, setShowUsersInDataRoomList] = useState(null);
    const [groupToLoad, setGroupToLoad] = useState(null);

    const showMobileMenuRef = useRef(showMobileMenu);
    const menuContainerRef = useRef();
    const menuRef = useRef();
    const mobileMenuContainerRef = useRef();
    const mobileMenuRef = useRef();

    const onKeydown = (e) => {
        if (e.code === 'KeyQ' && !showMobileMenuRef.current) {
            console.log('keydown');
            // showSearchRef.current = true;
            setShowMenu('pre');
            setShowMobileMenu(true);
            showMobileMenuRef.current = true;
        }
    };

    const onKeyup = (e) => {
        if (e.code === 'KeyQ') {
            // showSearchRef.current = false;
            setShowMobileMenu('pre');
            showMobileMenuRef.current = 'pre';
        }
    };

    const onMouseMove = (e) => {
        if (!showMobileMenuRef.current) {
            // console.log('>>>', showMenu);
            let obj = {
                x: e.clientX,
                y: e.clientY
            };
            setMousePosition(obj);
        };
    };

    const handleDataRoomOptionChange = (key, value) => {
        let clone = { ...dataRoomOptions };
        clone[key] = value;
        setDataRoomOptions(clone);
    };

    const changeInspectorWidth = () => {
        inspectorWidth <= 30 ? setInspectorWidth(inspectorWidth + 5) : setInspectorWidth(25);
    };

    const handleRemoveDataRoom = (dataRoom) => {


        let dataClone = { ...data };
        delete dataClone[dataRoom];
        setData(dataClone);

        let rootsClone = { ...roots };
        delete rootsClone[dataRoom];
        setRoots(rootsClone);

        let dataArr = Object.keys(dataClone);
        let newCurrentRoom = dataArr[0];
        setCurrentDataRoom(newCurrentRoom);
        setSocketCommand({ type: 'disconnectFromDR', data: dataRoom });
        // setCameraPosition(calculateCameraPosition(roots[newCurrentRoom], cameraRef.current));

    };

    const handleChangeDataRoom = (name) => {
        setCurrentDataRoom(name)
        setClickedElement(null);
        setViewLevel(0);
    };

    const handleGrow = () => {

        if (availableDataRooms.includes(selectedDataRoom)) setSocketCommand({ type: 'dataRoomRequest', data: selectedDataRoom })
        else if (Object.keys(availableDataRoomGroups).includes(selectedDataRoom)) {
            setGroupToLoad(availableDataRoomGroups[selectedDataRoom]);
            // for (const dataRoom of availableDataRoomGroups[selectedDataRoom]) {
            //     console.log('>>>>>>', dataRoom)
            //     setSocketCommand({ type: 'dataRoomRequest', data: dataRoom })
            // };
        };
        setShowAvailableDataRooms(!showAvailableDataRooms);

    };

    useEffect(() => {

        document.body.addEventListener('keydown', onKeydown);
        document.body.addEventListener('keyup', onKeyup);
        document.body.addEventListener('mousemove', onMouseMove);

    }, []);

    useEffect(() => {

        if (!data || !groupToLoad) return;

        console.log('data', data)
        console.log('group to load', groupToLoad)
        let groupToLoadClone = [...groupToLoad]
        groupToLoadClone = groupToLoadClone.filter(elem => !Object.keys(data).includes(elem))
        console.log('group to load clone', groupToLoadClone)
        setGroupToLoad(groupToLoadClone)

    }, [data]);

    useEffect(() => {

        if (!groupToLoad) return;

        if (groupToLoad.length > 0) {
            setTimeout(() => {
                setSocketCommand({ type: 'dataRoomRequest', data: groupToLoad[0] })
            }, 200);
        } else setGroupToLoad(null);

    }, [groupToLoad]);

    useEffect(() => {

        if (showMenu === 'pre') {
            gsap.to(menuContainerRef.current, {
                opacity: 0,
                duration: 0.3,
                onComplete: () => setShowMenu(false)
            })
        } else if (showMenu) {
            const q = gsap.utils.selector(menuRef);
            let tl = gsap.timeline();
            tl.to(menuContainerRef.current, {
                opacity: 1,
                duration: 0.2
            }).to(q(".menuOption"), {
                opacity: 1,
                transform: 'translateY(10px)',
                duration: 0.3,
                stagger: 0.04,
            });
        };

    }, [showMenu]);

    useEffect(() => {

        if (showMobileMenu === 'pre') {
            const q = gsap.utils.selector(mobileMenuRef);
            gsap.to(q(".menuOption"), {
                opacity: 0,
                transform: 'translateY(10px)',
                duration: 0.2,
                onComplete: () => {
                    setShowMobileMenu(false);
                    showMobileMenuRef.current = false;
                }
            });
        } else if (showMobileMenu) {
            const q = gsap.utils.selector(mobileMenuRef);
            gsap.to(q(".menuOption"), {
                opacity: 1,
                transform: 'translateY(10px)',
                duration: 0.3,
                stagger: 0.04,
            });
        };

    }, [showMobileMenu]);

    useEffect(() => {
        if (currentDataRoom) setSocketCommand({ type: 'requestUpdaterStatus', data: null })
    }, [currentDataRoom]);


    return (
        <div id="dashboard">
            <div
                id="statusBar"
                onClick={(e) => { e.stopPropagation() }}
            >
                <div id="statusBarLeft">
                    <img src="/logos/logo2-filetree.png" />
                    {data && Object.entries(data).map(([key, value], i, arr) => {

                        return (

                            <div
                                key={key}
                            >
                                <div
                                    id={key === currentDataRoom ? "currentDataRoom" : null}
                                    className="visibleDataRoom"
                                    onClick={() => { if (key !== currentDataRoom) handleChangeDataRoom(key) }}
                                >
                                    {key}
                                    <span
                                        onMouseOver={() => setShowUsersInDataRoomList(key)}
                                        onMouseOut={() => setShowUsersInDataRoomList(null)}
                                    >
                                        [{onlineUsers[key] && onlineUsers[key].length}]
                                    </span>
                                    {showUsersInDataRoomList === key &&
                                        <div>
                                            {onlineUsers[key].map(elem => {
                                                return (
                                                    <p
                                                        className="userInDataRoomListItem"
                                                        style={{ color: key === currentDataRoom ? themes[currentTheme].altText : themes[currentTheme].text }}
                                                    >
                                                        {elem}
                                                    </p>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                                {arr.length > 1 && showUsersInDataRoomList !== key &&
                                    <p
                                        className="dataRoomMinusButton"
                                        style={{ marginLeft: key === currentDataRoom ? '15px' : '0px' }}
                                        onClick={() => handleRemoveDataRoom(key)}
                                    >
                                        -
                                    </p>
                                }
                            </div>

                        )

                    })}
                    {availableDataRooms &&
                        <div>
                            <p
                                className="dataRoomPlusButton"
                                onClick={() => setShowAvailableDataRooms(!showAvailableDataRooms)}
                            >
                                {showAvailableDataRooms ? '-' : '+'}
                            </p>
                            {/* <AddDRIcon
                                showAvailableDataRooms={showAvailableDataRooms}
                                setShowAvailableDataRooms={setShowAvailableDataRooms}
                            /> */}
                            {loading &&
                                <Loading />
                            }
                        </div>
                    }
                    {availableDataRooms && showAvailableDataRooms && availableDataRoomGroups && !loading &&
                        <div
                            id="availableDataRoomsButton"
                        >
                            <div id="availableDataRoomsOptionsContainer">
                                <div id="availableDataRoomsOptions">
                                    {availableDataRooms.map(elem => {
                                        if (!data[elem]) {
                                            return (
                                                <p
                                                    key={elem}
                                                    className={elem === selectedDataRoom ? "availableDataRoomsOption selectedDataRoom" : "availableDataRoomsOption"}
                                                    onClick={(e) => { e.stopPropagation(); setSelectedDataRoom(elem) }}
                                                >
                                                    {elem}
                                                </p>
                                            )
                                        }
                                    })}
                                    <div id="groupDivider">----</div>
                                    {Object.entries(availableDataRoomGroups).map(([key, value]) => {
                                        return (
                                            <p
                                                key={key}
                                                className={key === selectedDataRoom ? "availableDataRoomsOption selectedDataRoom" : "availableDataRoomsOption"}
                                                onClick={(e) => { e.stopPropagation(); setSelectedDataRoom(key) }}
                                            >
                                                {key}
                                            </p>
                                        )
                                    })}
                                </div>
                                <div
                                    className="growButton"
                                    onClick={() => handleGrow()}
                                >
                                    Grow
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {dashboardVisible &&
                    <div id="statusBarRight">
                        {panelOrder.length > 0 &&
                            <p
                                id="inspectorWidthButton"
                                onClick={() => changeInspectorWidth()}
                            >
                                {inspectorWidth}%
                            </p>
                        }
                        <UsersIcon
                            inMenu={true}
                            clickable={true}
                            panelOrder={panelOrder}
                            setPanelOrder={setPanelOrder}
                        />
                        <FolderContentsIcon
                            inMenu={true}
                            clickable={true}
                            panelOrder={panelOrder}
                            setPanelOrder={setPanelOrder}
                        />
                        <SearchIcon
                            inMenu={true}
                            clickable={true}
                            panelOrder={panelOrder}
                            setPanelOrder={setPanelOrder}
                        />
                        <MapIcon
                            inMenu={true}
                            clickable={true}
                            panelOrder={panelOrder}
                            setPanelOrder={setPanelOrder}
                        />
                        <SettingsIcon
                            inMenu={true}
                            clickable={true}
                            panelOrder={panelOrder}
                            setPanelOrder={setPanelOrder}
                        />
                        <p>{account.firstName}</p>
                    </div>
                }
                {dashboardVisible && !panelOrder.includes('search') &&
                    <SearchBar
                        data={data}
                        treeData={treeData}
                        valuesOfType={valuesOfType}
                        setValuesOfType={setValuesOfType}
                        currentSearchTextInput={currentSearchTextInput}
                        setCurrentSearchTextInput={setCurrentSearchTextInput}
                        dropDownLimit={dropDownLimit}
                        setDropDownLimit={setDropDownLimit}
                        currentSearch={currentSearch}
                        setCurrentSearch={setCurrentSearch}
                        setSearchResults={setSearchResults}
                        numOfMatches={numOfMatches}
                        setNumOfMatches={setNumOfMatches}
                        searchType={searchType}
                        searchCategory={searchCategory}
                        viewLevel={viewLevel}
                        setPathToResultItems={setPathToResultItems}
                        roots={roots}
                        currentDataRoom={currentDataRoom}
                        setCurrentAccessActivitySearch={setCurrentAccessActivitySearch}
                        setAccessResults={setAccessResults}
                        setActivityResults={setActivityResults}
                        location={'menu'}
                    />
                }
                {dashboardVisible && updaterStatus && currentDataRoom &&
                    <UpdaterIcon
                        updaterStatus={updaterStatus}
                        dataRoom={currentDataRoom}
                        setSocketCommand={setSocketCommand}
                    />
                }
                {dashboardVisible && showMobileMenu &&
                    <div id="mobileMenuContainer" ref={mobileMenuContainerRef} style={{ top: `${mousePosition.y - 30}px`, left: `calc(${mousePosition.x - 100}px)` }}>
                        <UsersIcon
                            inMenu={true}
                            clickable={true}
                            panelOrder={panelOrder}
                            setPanelOrder={setPanelOrder}
                        />
                        <FolderContentsIcon
                            inMenu={true}
                            clickable={true}
                            panelOrder={panelOrder}
                            setPanelOrder={setPanelOrder}
                        />
                        <SearchIcon
                            inMenu={true}
                            clickable={true}
                            panelOrder={panelOrder}
                            setPanelOrder={setPanelOrder}
                        />
                        <MapIcon
                            inMenu={true}
                            clickable={true}
                            panelOrder={panelOrder}
                            setPanelOrder={setPanelOrder}
                        />
                        <SettingsIcon
                            inMenu={true}
                            clickable={true}
                            panelOrder={panelOrder}
                            setPanelOrder={setPanelOrder}
                        />
                    </div>
                }
            </div>

        </div>
    )
}
