import React, { useEffect, useState, useRef } from 'react';
import { Canvas, useFrame, useThree, extend } from '@react-three/fiber';
import { Sky, PerspectiveCamera, Effects, BakeShadows, Sparkles, Text } from '@react-three/drei';
import { EffectComposer, Selection, Outline } from '@react-three/postprocessing';
import * as THREE from 'three';
import { calculateCameraPosition, calculatePath, organiseDataBy } from '../Utils/Utils.js';
import { gsap } from "gsap";
import { Tree } from './Elements/Tree';
import { Ground } from './Elements/Ground';
import { dataConfig } from '../Utils/Utils.js';
import { InspectorAddRemove } from '..//Utils/Utils.js';

import './MainVisual.css';


export default function MainVisual(props) {

    // Props ============================================================================

    const {
        data,
        setData,
        currentDataRoom,
        setCurrentDataRoom,
        // treeData,
        dashboardVisible,
        setDashboardVisible,
        setLoading,
        viewLevel,
        setViewLevel,
        highestDepth,
        setHighestDepth,
        revealThreshold,
        highlightedPath,
        setHighlightedPath,
        cameraPosition,
        setCameraPosition,
        panelOrder,
        setPanelOrder,
        inspectorWidth,
        setFilesToInspect,
        showBranchLabels,
        searchResults,
        clickedElement,
        setClickedElement,
        treePositions,
        setTreePositions,
        mapCamera,
        setMapCamera,
        scene,
        setScene,
        currentTheme,
        themes,
        roots,
        setRoots,
        onlineUsers,
        showFiles,
        pathToResultItems,
        updateTree,
        setUpdateTree,
        currentAccessActivitySearch,
        accessResults,
        activityResults,
        updatedItems,
        setUpdatedItems
    } = props;


    // State ============================================================================

    const [init, setInit] = useState(false);
    const [currentZone, setCurrentZone] = useState(null);
    const [treeLoadComplete, setTreeLoadComplete] = useState(false);
    const [overview, setOverview] = useState(false);
    const [dataOrganisedByZone, setDataOrganisedByZone] = useState(null);
    const [dataOrganisedByDepth, setDataOrganisedByDepth] = useState(null);
    const [jumpTo, setJumpTo] = useState(null);

    const cameraRef = useRef();
    const mapCameraRef = useRef();
    const forestRef = useRef();

    // const { scene } = useThree();


    // Functions ========================================================================

    const CameraMove = () => {

        if (!cameraRef.current || !cameraPosition) return;

        let { x, y, z, d } = cameraPosition;

        x = viewLevel === 0 && panelOrder.length > 0 ? x - ((inspectorWidth + 20) / -10) : x;
        d = d === 4 && treeLoadComplete ? 1 : d;

        gsap.to(cameraRef.current.position, {
            x: x,
            y: y,
            z: z,
            duration: 1,
            ease: "power3.inOut",
            onComplete: () => {
                if (d === 4) setTreeLoadComplete(true);
            }
        });

        // console.log(cameraRef.current.position);

    };

    const CameraMotion = () => {

        const [vec] = useState(() => new THREE.Vector3());
        const { mouse } = useThree();
        const { clock } = useThree();


        useFrame(() => {

            const elapsedTime = clock.getElapsedTime();
            let dX, dY, dZ;

            if (mouse.x > -0.3 && mouse.x < 0.3) {
                dX = 0;
            } else {
                dX = mouse.x * (0.1);
                // dX = mouse.x * ((1 / (viewLevel + 1)) * 0.1);
            };

            if (mouse.y > -0.3 && mouse.y < 0.3) {
                dY = 0;
            } else {
                dY = mouse.y * (0.1);
                // dY = mouse.y * ((1 / (viewLevel + 1)) * 0.1);
            };

            let lX = cameraRef.current.position.x + dX;
            let lY = cameraRef.current.position.y + dY;
            let lZ = cameraRef.current.position.z;

            cameraRef.current.position.lerp(vec.set(lX, lY, lZ), 0.05);

        })

        return null;

    };

    const MapCameraMove = () => {

        if (!cameraRef.current || !roots[currentDataRoom]) return;

        let { x, y, z } = calculateCameraPosition(roots[currentDataRoom], cameraRef.current);

        gsap.to(mapCameraRef.current.position, {
            x: x,
            y: y,
            z: z,
            duration: 1,
            ease: "power3.inOut"
        });

    };

    const Overview = () => {

        if (overview && Object.keys(data).length > 1) {
            let overviewPos = calculateCameraPosition(forestRef.current, cameraRef.current);
            overviewPos.d = 1;
            setCameraPosition(overviewPos);
            setCurrentDataRoom(null);
            setViewLevel(-1);
            setClickedElement(null);
            setHighlightedPath([]);
            setOverview(false);
        } else {
            setOverview(false);
        };

    };

    const MapCameraExport = () => {

        let { scene } = useThree();

        useFrame(() => {

            setScene(scene);
            setMapCamera(mapCameraRef.current);

        })

        return null;
    };

    const handleWheel = (e) => {
        if (e.deltaY > 200) setOverview(true);
    };


    // Effects ==========================================================================

    useEffect(() => {
        if (data) {
            setInit(true);
        };
    }, [data]);

    useEffect(() => {
        if (init, data, currentDataRoom) {
            let dByZ = organiseDataBy('zone', data[currentDataRoom]);
            setDataOrganisedByZone(Object.fromEntries(
                Object.entries(dByZ).sort(([, a], [, b]) => b.depth - a.depth)
            ));
            // setDataOrganisedByZone(organiseDataBy('zone', data[currentDataRoom]));
            setDataOrganisedByDepth(organiseDataBy('depth', data[currentDataRoom]));
        };
    }, [init, data, currentDataRoom]);

    useEffect(() => {
        if (currentDataRoom) {
            setHighlightedPath([]);
            setCurrentZone(null);
            setViewLevel(0);
            setFilesToInspect([]);
            if (roots[currentDataRoom]) {
                let item = scene.getObjectByProperty('ID', roots[currentDataRoom].ID)
                setCameraPosition(calculateCameraPosition(item, cameraRef.current))
                // setCameraPosition(calculateCameraPosition(roots[currentDataRoom], cameraRef.current))
            };
            if (!dashboardVisible) setDashboardVisible(true)
        }

    }, [currentDataRoom, roots]);

    useEffect(() => {

        if (clickedElement) {

            let path = calculatePath(clickedElement, data[currentDataRoom], roots[currentDataRoom].ID);
            setHighlightedPath(path);

            setCurrentZone(data[currentDataRoom][clickedElement].zone);

            if (data[currentDataRoom][clickedElement].nonFolderDescendants.length) {
                let files = data[currentDataRoom][clickedElement].nonFolderDescendants;
                // let files = treeData[clickedElement].nonFolderDescendants.filter(file => treeData[file].type === 'file');
                setFilesToInspect(files);
                if (!panelOrder.includes('contents')) setPanelOrder(InspectorAddRemove([...panelOrder], 'contents'));
            } else {
                setFilesToInspect([]);
            };

            setViewLevel(data[currentDataRoom][clickedElement].depth);
        } else {
            setHighlightedPath([]);
            setCurrentZone(null);
            setViewLevel(0);
            setFilesToInspect([]);
        };

    }, [clickedElement]);

    useEffect(() => {

        console.log('current zone', currentZone);

    }, [currentZone]);

    useEffect(() => {
        if (treeLoadComplete) setDashboardVisible(true);
    }, [roots]);

    useEffect(() => {
        if (updateTree) {
            console.log('update tree')
            setTimeout(() => {
                setUpdateTree(false)
                console.log('re-render')
            }, 1)
        };
    }, [updateTree])

    // useEffect(() => {
    //     if (jumpTo) {
    //         console.log('jump to', jumpTo)
    //         setCameraPosition(calculateCameraPosition(jumpTo, cameraRef.current))
    //         setTimeout(() => {
    //             setJumpTo(null)
    //         }, 2000)
    //     } else {
    //         setUpdatedItems([])
    //     }
    // }, [jumpTo])


    // Render ===========================================================================

    return (
        <div
            id="visual"
            onWheel={(e) => handleWheel(e)}
        >
            <Canvas>
                <color attach="background" args={[themes[currentTheme].background]} />
                <fog attach="fog" args={[themes[currentTheme].background, 0, 170]} />
                <PerspectiveCamera
                    makeDefault
                    ref={cameraRef}
                />
                <CameraMove
                    cameraPosition={cameraPosition}
                    panelOrder={panelOrder}
                    inspectorWidth={inspectorWidth}
                    viewLevel={viewLevel}
                />
                {/* <CameraMotion /> */}
                <PerspectiveCamera
                    ref={mapCameraRef}
                    aspect={(window.innerWidth * (inspectorWidth * 0.01)) / 500}
                    fov={45}
                    onUpdate={self => self.updateProjectionMatrix()}
                />
                <MapCameraMove
                    currentDataRoom={currentDataRoom}
                />
                <MapCameraExport />
                <Overview
                    data={data}
                    overview={overview}
                />
                <ambientLight />
                <pointLight position={[10, 50, 50]} />
                {init &&
                    <Selection>
                        <EffectComposer multisampling={8} autoClear={false}>
                            <Outline visibleEdgeColor="white" hiddenEdgeColor="white" edgeStrength={50} width={3000} />
                        </EffectComposer>
                        <group
                            ref={forestRef}
                        >
                            {Object.entries(data).map(([key, value], i, arr) => {

                                return (

                                    <Tree
                                        key={key}
                                        data={data}
                                        name={key}
                                        index={i}
                                        currentDataRoom={currentDataRoom}
                                        setCurrentDataRoom={setCurrentDataRoom}
                                        treeData={value}
                                        viewLevel={viewLevel}
                                        setViewLevel={setViewLevel}
                                        highestDepth={highestDepth}
                                        revealThreshold={revealThreshold}
                                        currentZone={currentZone}
                                        clickedElement={clickedElement}
                                        setClickedElement={setClickedElement}
                                        highlightedPath={highlightedPath}
                                        cameraPosition={cameraPosition}
                                        setCameraPosition={setCameraPosition}
                                        cameraRef={cameraRef}
                                        panelOrder={panelOrder}
                                        inspectorWidth={inspectorWidth}
                                        setLoading={setLoading}
                                        setDashboardVisible={setDashboardVisible}
                                        showBranchLabels={showBranchLabels}
                                        searchResults={searchResults}
                                        treePositions={treePositions}
                                        setTreePositions={setTreePositions}
                                        currentTheme={currentTheme}
                                        themes={themes}
                                        roots={roots}
                                        setRoots={setRoots}
                                        onlineUsers={onlineUsers}
                                        showFiles={showFiles}
                                        pathToResultItems={pathToResultItems}
                                        updateTree={updateTree}
                                        dataOrganisedByZone={dataOrganisedByZone}
                                        dataOrganisedByDepth={dataOrganisedByDepth}
                                        currentAccessActivitySearch={currentAccessActivitySearch}
                                        accessResults={accessResults}
                                        activityResults={activityResults}
                                        updatedItems={updatedItems}
                                        setUpdatedItems={setUpdatedItems}
                                        setJumpTo={setJumpTo}
                                    />

                                )

                            })}
                        </group>
                        <Ground
                            currentTheme={currentTheme}
                            themes={themes}
                        />
                    </Selection>
                }
            </Canvas>
        </div>
    )
}