import React, { useEffect, useRef, forwardRef } from 'react';
import * as THREE from 'three';
import Perlin from 'perlin.js';

Perlin.seed(Math.random());

export function Ground(props) {

    const {
        currentTheme,
        themes
    } = props;

    const geom = useRef();

    useEffect(() => {
        const vertex = new THREE.Vector3()
        const normal = new THREE.Vector3()
        let newPositionAttribute = []
        const positionAttribute = geom.current.getAttribute('position')
        const normalAttribute = geom.current.getAttribute('normal')
        for (let i = 0; i < positionAttribute.count; i++) {
            vertex.fromBufferAttribute(positionAttribute, i)
            normal.fromBufferAttribute(normalAttribute, i)
            const v = vertex.multiplyScalar(0.5)
            const n = Perlin.simplex3(...v.toArray())
            vertex.add(normal.multiplyScalar(n * 2))
            newPositionAttribute.push(vertex.x, vertex.y, vertex.z)
        }
        geom.current.setAttribute('position', new THREE.Float32BufferAttribute(newPositionAttribute, 3))
        geom.current.attributes.position.needsUpdate = true
        geom.current.computeVertexNormals()
    }, [])

    return (
        <group>
            <mesh
                position={[0, -0.01, 0]}
                rotation={[Math.PI / 2, 0, 0]}
                receiveShadow
                castShadow
            >
                <planeGeometry
                    ref={geom}
                    args={[5000, 5000, 300, 300]}
                />
                <meshStandardMaterial
                    color={themes[currentTheme].mesh}
                    side={THREE.DoubleSide}
                    transparent={true}
                    opacity={0.3}
                    wireframe={true}
                />
            </mesh>
            <mesh
                position={[0, -2, 0]}
                rotation={[Math.PI / 2, 0, 0]}
                receiveShadow
                castShadow
            >
                <planeGeometry
                    args={[4000, 4000, 1, 1]}
                />
                <meshStandardMaterial
                    color={themes[currentTheme].ground}
                    transparent={true}
                    opacity={1}
                    side={THREE.DoubleSide}
                />
            </mesh>
        </group>
    )

};