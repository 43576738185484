import React, { useEffect, useState, useRef } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { gsap } from "gsap";
import MapIcon from '../../../Icons/MapIcon.js';
import MaximizeIcon from '../../../Icons/MaximizeIcon.js';
import MoveComponentIcon from '../../../Icons/MoveComponentIcon.js';
import './Map.css';
export default function Map(props) {

    const {
        scene,
        mapCamera,
        panelOrder,
        setPanelOrder,
        showMiniMap,
        setShowMiniMap,
        componentState,
        setComponentState,
        inspectorWidth,
        rearrange
    } = props;

    const [componentPosition, setComponentPosition] = useState(null);

    const componentRef = useRef();
    const canvasRef = useRef();

    const CustomCamera = () => {

        let { gl } = useThree();

        useFrame(() => {
            gl.autoClear = true;
            gl.render(scene, mapCamera)
            gl.autoClear = false;
            gl.clearDepth()
        })

        return null;

    };


    // ==========================================================================================================================================================

    return (
        <div
            ref={componentRef}
            id="mapCanvasContainer"
            onClick={(e) => { e.stopPropagation() }}
            onMouseMove={(e) => { e.stopPropagation() }}
            style={{
                position: componentState.map.beingDragged ? 'absolute' : 'relative',
                top: componentState.map.beingDragged ? `${componentPosition.y}px` : '0px',
                left: componentState.map.beingDragged ? `${componentPosition.x}px` : '0px',
                marginTop: componentState.map.beingDragged && '0px',
                width: rearrange ? `calc(${inspectorWidth}vw - 24px)` : `calc(100% - 24px)`,
                height: componentState.map.maximize ? '500px' : '50px'
            }}
        >
            <MapIcon
                inMenu={false}
                clickable={true}
                panelOrder={panelOrder}
                setPanelOrder={setPanelOrder}
                showMiniMap={showMiniMap}
                setShowMiniMap={setShowMiniMap}
            />
            <div
                id="mapCanvasContainerInner"
                style={{ display: componentState.map.maximize ? 'block' : 'none' }}
            >
                <Canvas
                    ref={canvasRef}
                >
                    <CustomCamera />
                </Canvas>
            </div>
            <div id="mapStatusBar">
                <MaximizeIcon
                    component={'map'}
                    componentState={componentState}
                    setComponentState={setComponentState}
                />
                <MoveComponentIcon
                    component={'map'}
                    componentRef={componentRef}
                    componentState={componentState}
                    setComponentState={setComponentState}
                    setComponentPosition={setComponentPosition}
                />
            </div>
        </div>
    )

};