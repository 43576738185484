import React, { useState } from 'react';
import { InspectorAddRemove } from '..//Utils/Utils.js';
import './MapIcon.css';

export default function MapIcon(props) {

    const {
        inMenu,
        clickable,
        panelOrder,
        setPanelOrder,
        showMiniMap,
        setShowMiniMap
    } = props;

    const [showLabel, setShowLabel] = useState(false);

    return (
        <div
            className={inMenu ? "mapIconContainerMenu" : "mapIconContainerStatusBar"}
            style={clickable ? {pointerEvents: 'auto', cursor: 'pointer'} : {pointerEvents: 'none', cursor: 'auto'}}
            onClick={() => setPanelOrder(InspectorAddRemove([...panelOrder], 'map'))}
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
        >
            <div className={inMenu ? "mapIcon" : "mapIcon mapIconAlt"}></div>
            {showLabel && inMenu &&
                <p className="label">map</p>
            }
        </div>
    )
}
