import React from 'react'
import './MaximizeIcon.css';

export default function MaximizeIcon(props) {

    const {
        component,
        componentState,
        setComponentState
    } = props;

    const setMaximize = (e) => {
        e.stopPropagation();
        let componentStateClone = {...componentState};
        componentStateClone[component].maximize = !componentStateClone[component].maximize;
        setComponentState(componentStateClone);
    };

    return (
        <div id="maximizeIconContainer" onClick={(e) => setMaximize(e)}>
            <div id="maximizeIcon">
                <div className={componentState[component].maximize ? 'bottomRight' : 'topLeft'}></div>
                <div className={componentState[component].maximize ? 'topLeft' : 'bottomRight'}></div>
            </div>
        </div>
    )
}