import React, { useEffect, useRef, useState } from 'react';
import SettingsIcon from '../../../Icons/SettingsIcon.js';
import MaximizeIcon from '../../../Icons/MaximizeIcon.js';
import MoveComponentIcon from '../../../Icons/MoveComponentIcon.js';
import { gsap } from "gsap";
import './Settings.css';

export default function Settings(props) {

    const {
        data,
        currentDataRoom,
        showSettings,
        setShowSettings,
        settings,
        setSettings,
        searchableValues,
        setSearchableValues,
        setSymbolToUpdate,
        setColourToUpdate,
        viewLevel,
        revealThreshold,
        setRevealThreshold,
        colourBy,
        setColourBy,
        panelOrder,
        setPanelOrder,
        showBranchLabels,
        setShowBranchLabels,
        currentTheme,
        setCurrentTheme,
        themes,
        highestDepth,
        showFiles,
        setShowFiles,
        componentState,
        setComponentState,
        inspectorWidth,
        rearrange
    } = props;

    const [componentPosition, setComponentPosition] = useState(null);

    const componentRef = useRef();
    const settingsPanelOptionsRef = useRef();

    const unCamel = (word) => {
        return word.replace(/([A-Z])/g, ' $1').toLowerCase();
    };

    const handleSetRevealThreshold = (num) => {

        let revealThresholdClone = { ...revealThreshold };
        revealThresholdClone[currentDataRoom] = num;
        setRevealThreshold(revealThresholdClone);

    };

    return (
        <div
            id="settings"
            ref={componentRef}
            style={{
                position: componentState.settings.beingDragged ? 'absolute' : 'relative',
                top: componentState.settings.beingDragged ? `${componentPosition.y}px` : '0px',
                left: componentState.settings.beingDragged ? `${componentPosition.x}px` : '0px',
                marginTop: componentState.settings.beingDragged && '0px',
                width: rearrange ? `calc(${inspectorWidth}vw - 24px)` : `calc(100% - 24px)`,
                height: componentState.settings.maximize ? '500px' : '50px'
            }}
            onClick={(e) => { e.stopPropagation() }}
            onMouseMove={(e) => { e.stopPropagation() }}
        >
            <SettingsIcon
                inMenu={false}
                clickable={true}
                panelOrder={panelOrder}
                setPanelOrder={setPanelOrder}
                showSettings={showSettings}
                setShowSettings={setShowSettings}
            />
            <div id="settingsStatusBar">
                <MaximizeIcon
                    component={'settings'}
                    componentState={componentState}
                    setComponentState={setComponentState}
                />
                <MoveComponentIcon
                    component={'settings'}
                    componentRef={componentRef}
                    componentState={componentState}
                    setComponentState={setComponentState}
                    setComponentPosition={setComponentPosition}
                />                
                {/* <p onClick={() => setOpenTab('information')}>information</p> */}
            </div>
            <div id="settingsPanelOptions" ref={settingsPanelOptionsRef}>
                <div className="panelOptionsBlock">
                    <div className="panelOptionSubBlock">
                        <p className="panelOptionHeader sub">Theme</p>
                        <div className="panelOptionContainer">
                            {Object.entries(themes).map(([key, value]) => {
                                return <p
                                    className={key === currentTheme ? "panelOption selected" : "panelOption"}
                                    onClick={() => setCurrentTheme(key)}
                                >
                                    {key}
                                </p>

                            })}
                        </div>
                        <p className="panelOptionHeader sub">Reveal Level</p>
                        {currentDataRoom &&
                            <div className="panelOptionContainer">
                                {[...Array(highestDepth[currentDataRoom] - viewLevel).keys()].map((num) => {
                                    num = num + 1;
                                    return <p
                                        className={revealThreshold[currentDataRoom] === num ? "panelOption selected" : "panelOption"}
                                        onClick={() => handleSetRevealThreshold(num)}
                                    >
                                        {num}
                                    </p>
                                })}
                            </div>
                        }
                        <p className="panelOptionHeader sub">Show Files</p>
                        <div className="panelOptionContainer">
                            <p className={showFiles ? "panelOption selected" : "panelOption"} onClick={() => setShowFiles(true)}>visible</p>
                            <p className={!showFiles ? "panelOption selected" : "panelOption"} onClick={() => setShowFiles(false)}>hidden</p>
                        </div>
                        <p className="panelOptionHeader sub">Branch Labels</p>
                        <div className="panelOptionContainer">
                            <p className={showBranchLabels ? "panelOption selected" : "panelOption"} onClick={() => setShowBranchLabels(true)}>visible</p>
                            <p className={!showBranchLabels ? "panelOption selected" : "panelOption"} onClick={() => setShowBranchLabels(false)}>hidden</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
