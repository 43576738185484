import React, { useEffect, useState, useRef, memo } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useCursor, Float, Html } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import * as THREE from 'three';
import { randomInt } from '../../Utils/Utils.js';

const Avatar = (props) => {

    const {
        name,
        themes,
        currentTheme,
        viewLevel,
        onlineUsers,
        userPositions,
        setUserPositions
    } = props;

    const {
        user
    } = props;

    const [hovered, setHover] = useState(false);
    useCursor(hovered);

    const avatarRef = useRef();

    const generatePosition = (clone) => {

        let arr = [];

        const recur = () => {

            let x = randomInt(-5, 5);
            let y = randomInt(1.5, 2.5);
            let z = randomInt(1.5, 5);

            if (!Object.keys(clone).legnth) {
                arr.push(x, y, z);
                return;
            } else {

                for (const [key, value] of Object.entries(clone)) {

                    if (value[0] === x && value[1] === y && value[2] === z) {
                        recur();
                        break;
                    } else arr.push(x, y, z);

                };

            };

        };

        recur();

        return arr;

    };

    useEffect(() => {

        if (!userPositions[user]) {

            let userPositionsClone = { ...userPositions };
            let position = generatePosition(userPositionsClone);
            userPositionsClone[user] = position;
            setUserPositions(userPositionsClone);

        };

    }, []);

    return (
        <Float
            speed={7} // Animation speed, defaults to 1
            rotationIntensity={0.1} // XYZ rotation intensity, defaults to 1
            floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[-0.1, 0.1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        >
            {userPositions[user] &&
                <group>
                    <mesh
                        ref={avatarRef}
                        position={userPositions[user]}
                        receiveShadow
                        castShadow
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                    >
                        <sphereGeometry args={[0.3, 32, 16]} />
                        <meshStandardMaterial
                            color={themes[currentTheme].avatar}
                            emissive={'#000000'}
                            emissiveIntensity={1}
                            roughness={1}
                            transparent={true}
                            opacity={1}
                        />
                    </mesh>
                    {hovered && avatarRef.current &&
                        <Html
                            position={[
                                avatarRef.current.position.x,
                                avatarRef.current.position.y + 1,
                                avatarRef.current.position.z
                            ]}
                        >
                            <p
                                style={{
                                    fontSize: '10px',
                                    color: themes[currentTheme].text,
                                    transform: 'translateX(-50%)'
                                }}
                            >
                                {user}
                            </p>
                        </Html>
                    }
                </group>
            }
        </Float>
    )

};

export default Avatar;