import React, { useEffect, useState, useRef, memo } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useCursor, Float, Html } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import * as THREE from 'three';
import Avatar from './Avatar';
import { randomInt } from '../../Utils/Utils.js';

const UserAvatars = (props) => {

    const {
        name,
        themes,
        currentTheme,
        viewLevel,
        onlineUsers
    } = props;

    const [userPositions, setUserPositions] = useState({});

    return (
        <>
            {(viewLevel === -1 || viewLevel === 0) && onlineUsers[name] && onlineUsers[name].map(elem => {

                return (
                    <Avatar
                        user={elem}
                        themes={themes}
                        currentTheme={currentTheme}
                        userPositions={userPositions}
                        setUserPositions={setUserPositions}
                    />
                )

            })}
        </>
    )

};

export default UserAvatars;