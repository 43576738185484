import React, { useEffect, useState, useRef } from 'react';
import Loading from '../Icons/Loading.js';
// import { io } from "socket.io-client";
// import WebSocket from 'ws';
import axios from "axios";
import './Login.css';

export default function Login(props) {

    const {
        account,
        setAccount,
        setSocketCommand,
        themes,
        currentTheme
    } = props;

    const [loggingIn, setLoggingIn] = useState(false);

    const usernameInputRef = useRef();
    const passwordInputRef = useRef();

    const handleLogin = () => {

        console.log('>>>', usernameInputRef.current.value);
        if (!usernameInputRef.current.value || !passwordInputRef.current.value) return;

        setLoggingIn(true);

        let url = `https://supacors.herokuapp.com/https://rvrauth.com/check-credentials`;

        let body = new FormData();
        body.append('username', usernameInputRef.current.value);
        body.append('password', passwordInputRef.current.value);
        body.append('ipAddress', '186.8576.976');

        axios.post(url, body)
            .then(function (response) {

                if (response.data.status === 'accessGranted') {

                    console.log('access granted');
                    setAccount(response.data);

                } else {
                    console.log(response.data.status);
                    setLoggingIn(false);
                };

            })
            .catch(function (error) {
                console.log('error', error);
                setLoggingIn(false);
            });

    };

    return (
        <div id="login">
            <div>
                <img src="/logos/logo2white.svg" />
                {!loggingIn &&
                    <form
                        id="loginButtonContainer"
                        autoComplete="on"
                        action=""
                        noValidate={true}
                        data-form-type="other"
                    >
                        <input
                            ref={usernameInputRef}
                            type="email"
                            name="email"
                            placeholder="email"
                            autoComplete="email"
                        ></input>
                        <input
                            ref={passwordInputRef}
                            type="password"
                            name="password"
                            placeholder="password"
                            autoComplete="current-password"
                        ></input>
                        <button
                            className="loginButton"
                            onClick={() => handleLogin()}
                        >
                            Login
                        </button>
                    </form>
                }
                {loggingIn &&
                    <Loading
                        color={themes[currentTheme].altText}
                        size={'24px'}
                    />
                }
            </div>
        </div>
    )
}
