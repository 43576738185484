import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '../../../Icons/SearchIcon.js';
import MaximizeIcon from '../../../Icons/MaximizeIcon.js';
import MoveComponentIcon from '../../../Icons/MoveComponentIcon.js';
import SearchBar from './SearchBar.js';
import { gsap } from "gsap";
import './Search.css';

export default function Search(props) {

    const {
        data,
        treeData,
        showSearch,
        setShowSearch,
        searchCategory,
        setSearchCategory,
        numOfMatches,
        setNumOfMatches,
        setCurrentSearch,
        searchableValues,
        currentSearch,
        panelOrder,
        setPanelOrder,
        setRevealThreshold,
        setSearchResults,
        setDropDownLimit,
        searchType,
        valuesOfType,
        setValuesOfType,
        currentSearchTextInput,
        setCurrentSearchTextInput,
        dropDownLimit,
        viewLevel,
        setPathToResultItems,
        roots,
        currentDataRoom,
        componentState,
        setComponentState,
        inspectorWidth,
        rearrange,
        setCurrentAccessActivitySearch,
        setAccessResults,
        setActivityResults,
        setSocketCommand
    } = props;

    const [componentPosition, setComponentPosition] = useState(null);

    const componentRef = useRef();

    const handleSearchCategorySelection = (e, key, val) => {
        let searchCategoryClone = [...searchCategory];
        if (searchCategoryClone.includes(key)) {
            searchCategoryClone = searchCategoryClone.filter(elem => elem !== key)
        } else {
            if (key === 'content') searchCategoryClone = [key]
            else {
                if (searchCategoryClone.includes('content')) searchCategoryClone = []
                searchCategoryClone.push(key)
            }
        };
        console.log(searchCategoryClone);
        setSearchCategory(searchCategoryClone);
    };

    const unCamel = (word) => {
        return word.replace(/([A-Z])/g, ' $1').toLowerCase();
    };

    return (
        <div ref={componentRef}
            id="search"
            style={{
                position: componentState.search.beingDragged ? 'absolute' : 'relative',
                top: componentState.search.beingDragged ? `${componentPosition.y}px` : '0px',
                left: componentState.search.beingDragged ? `${componentPosition.x}px` : '0px',
                marginTop: componentState.search.beingDragged && '0px',
                width: rearrange ? `calc(${inspectorWidth}vw - 24px)` : `calc(100% - 24px)`,
                height: componentState.search.maximize ? '500px' : '50px'
            }}
            onClick={(e) => { e.stopPropagation() }}
            onMouseMove={(e) => { e.stopPropagation() }}
        >
            <SearchIcon
                inMenu={false}
                clickable={true}
                panelOrder={panelOrder}
                setPanelOrder={setPanelOrder}
                showSearch={showSearch}
                setShowSearch={setShowSearch}
            />
            <div id="searchStatusBar">
                <MaximizeIcon
                    component={'search'}
                    componentState={componentState}
                    setComponentState={setComponentState}
                />
                <MoveComponentIcon
                    component={'search'}
                    componentRef={componentRef}
                    componentState={componentState}
                    setComponentState={setComponentState}
                    setComponentPosition={setComponentPosition}
                />
            </div>
            <div
                id="searchContainerInner"
                style={{ display: componentState.search.maximize ? 'flex' : 'none' }}
            >
                <div id="searchCategoryOptions">
                    {searchableValues &&
                        Object.entries(searchableValues).map(([key, val]) => {
                            if (val.type === 'string') {
                                return <p
                                    className={searchCategory.includes(key) ? 'searchCategory searchCategorySelected' : 'searchCategory searchCategoryUnselected'}
                                    onClick={((e) => handleSearchCategorySelection(e, key, val))}
                                >{unCamel(key)}</p>
                            }
                        })
                    }
                </div>
                <SearchBar
                    data={data}
                    treeData={treeData}
                    valuesOfType={valuesOfType}
                    setValuesOfType={setValuesOfType}
                    currentSearchTextInput={currentSearchTextInput}
                    setCurrentSearchTextInput={setCurrentSearchTextInput}
                    dropDownLimit={dropDownLimit}
                    setDropDownLimit={setDropDownLimit}
                    currentSearch={currentSearch}
                    setCurrentSearch={setCurrentSearch}
                    setSearchResults={setSearchResults}
                    numOfMatches={numOfMatches}
                    setNumOfMatches={setNumOfMatches}
                    searchType={searchType}
                    searchCategory={searchCategory}
                    viewLevel={viewLevel}
                    setPathToResultItems={setPathToResultItems}
                    roots={roots}
                    currentDataRoom={currentDataRoom}
                    setCurrentAccessActivitySearch={setCurrentAccessActivitySearch}
                    setAccessResults={setAccessResults}
                    setActivityResults={setActivityResults}
                    setSocketCommand={setSocketCommand}
                    location={'inspector'}
                />
            </div>
        </div >
    )
}
